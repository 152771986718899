<template>
    <div>
        <div class="float-right mt-3">
            <b-btn variant="success" size="md" @click="addItem"> +
            </b-btn>
        </div>
        <div class="p-4">
                <b-modal id="addDeliveryExceptionModal" title="Add Delivery Exception"
                         :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                         hide-footer
                         >
                    <add-delivery-exception @success="ItemAdded"></add-delivery-exception>
                </b-modal>
                <vue-table class="mt-5" :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn variant="info" size="sm" @click="editItem(props.rowData)">
                                <b-icon-pencil-square></b-icon-pencil-square>
                                Edit
                            </b-btn>
                            <b-btn variant="danger" size="sm" @click="deleteItem(props.rowData.id)">
                                <b-icon-trash-fill></b-icon-trash-fill>
                                Delete
                            </b-btn>
                        </div>
                    </template>
                </vue-table>
                <b-modal id="editDeliveryExceptionModal" :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                         hide-footer
                         title="Edit Delivery Exception">
                    <edit-delivery-exception :model="EditingModel" @success="itemEdited"></edit-delivery-exception>
                </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import AddDeliveryException from './AddDeliveryException';
import EditDeliveryException from './EditDeliveryException';

export default {
    name: 'ListDeliveryException',
    components: { EditDeliveryException, AddDeliveryException },
    data () {
        return {
            apiBase: urls.admin.masterData.deliveryException.list,
            EditingModel: {
                id: '',
                exception_unit: '',
                exception_price: '',
                exception_weight: ''
            },
            EditingException: null,
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'exception_unit',
                    sortField: 'exception_unit',
                    title: 'Exception Unit'
                },
                {
                    name: 'exception_price',
                    sortField: 'exception_price',
                    title: 'Exception Price'
                },
                {
                    name: 'exception_weight',
                    sortField: 'exception_weight',
                    title: 'Exception Weight'
                },
                {
                    name: 'disable',
                    sortField: 'disable',
                    title: 'Active / Inactive'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        addItem () {
            this.$bvModal.show('addDeliveryExceptionModal');
        },
        ItemAdded () {
            this.$bvModal.hide('addDeliveryExceptionModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.EditingException = item;
            this.EditingModel.id = item.id;
            this.EditingModel.exception_unit = item.exception_unit;
            this.EditingModel.exception_price = item.exception_price;
            this.EditingModel.exception_weight = item.exception_weight;
            this.EditingModel.disable = item.disable;
            this.$bvModal.show('editDeliveryExceptionModal');
        },
        itemEdited () {
            this.$bvModal.hide('editDeliveryExceptionModal');
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const conform = confirm('Are you sure?');
            if (!conform) {
                return;
            }
            const that = this;
            axios.form(urls.admin.masterData.deliveryException.delete, { id: item }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.deleteSuccess();
                } else {
                    that.deleteFailure(json);
                }
            }).catch(function () {
                that.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        },
        deleteSuccess () {
            this.$notify('Successfully deleted Delivery Exception..!', 'success',
                {
                    type: 'error',
                    duration: 3000,
                    permanent: false
                });
            this.$refs.table.refreshTable();
        },
        deleteFailure (json) {
            // console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        }
    }
};
</script>

<style scoped>

</style>
