<template>
    <div>
        <div class="float-right mt-3">
            <b-btn variant="success" size="md" @click="addItem"> +
            </b-btn>
        </div>
        <div class="p-4">
            <b-modal id="addTACModal" title="Add Delivery Charge"
                     :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                     hide-footer>
                <AddTermsAndCondition @success="ItemAdded"></AddTermsAndCondition>
            </b-modal>
            <vue-table class="mt-5" :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <b-btn v-if="props.rowData.disabled" variant="success" size="sm" @click="activateDeactivate(props.rowData)">
                            <span><b-icon-check-circle></b-icon-check-circle> Activate</span>
                        </b-btn>
                        <b-btn v-else variant="warning" size="sm" @click="activateDeactivate(props.rowData)">
                            <span ><b-icon-x-circle></b-icon-x-circle> Deactivate</span>
                        </b-btn>
                        <b-btn variant="info" size="sm" @click="editItem(props.rowData)">
                            <b-icon-pencil-square></b-icon-pencil-square>
                            Edit
                        </b-btn>
                        <b-btn variant="danger" size="sm" @click="deleteItem(props.rowData.id)">
                            <b-icon-trash-fill></b-icon-trash-fill>
                            Delete
                        </b-btn>
                    </div>
                </template>
            </vue-table>
            <b-modal id="editTACModal" :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                     hide-footer
                     title="Edit Delivery Charge">
                <EditTermsAndCondition :model="EditingModel" @success="itemEdited"></EditTermsAndCondition>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import AddTermsAndCondition from './AddTermsAndCondition';
import EditTermsAndCondition from './EditTermsAndCondition';

export default {
    name: 'TermsAndCondition',
    components: { AddTermsAndCondition, EditTermsAndCondition },
    data () {
        return {
            apiBase: urls.admin.masterData.termsAndCondition.list,
            EditingModel: {
                id: '',
                tac_file: '',
                disable: ''
            },
            EditingTAC: null,
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'tac_file',
                    sortField: 'tac_file',
                    title: 'Tac File'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        addItem () {
            this.$bvModal.show('addTACModal');
        },
        ItemAdded () {
            this.$bvModal.hide('addTACModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.EditingTAC = item;
            this.EditingModel.id = item.id;
            this.EditingModel.tac_file = item.state;
            this.$bvModal.show('editTACModal');
        },
        itemEdited () {
            this.$bvModal.hide('editTACModal');
            this.$refs.table.refreshTable();
        },
        activateDeactivate (rowData) {
            const conform = confirm('Are you sure?');
            if (!conform) {
            }
            const that = this;
            axios.form(urls.admin.masterData.termsAndCondition.activateDeactivate, { id: rowData.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify(json.message, 'success',
                        {
                            type: 'warning',
                            duration: 3000,
                            permanent: false
                        });
                    that.$refs.table.refreshTable();
                } else {
                    // console.log(json);
                    that.$notify(json.errors, 'Error',
                        {
                            type: 'warning',
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            }).catch(function () {
                that.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        },
        deleteItem (item) {
            const conform = confirm('Are you sure?');
            if (!conform) {
                return;
            }
            const that = this;
            axios.form(urls.admin.masterData.termsAndCondition.delete, { id: item }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.deleteSuccess();
                } else {
                    that.deleteFailure(json);
                }
            }).catch(function () {
                that.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        },
        deleteSuccess () {
            this.$notify('Successfully deleted TAC File..!', 'success',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                });
            this.$refs.table.refreshTable();
        },
        deleteFailure (json) {
            // console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        }
    }
};
</script>

<style scoped>

</style>
