<template>
    <div class="m-4">
        <b-tabs class="card p-4">
            <b-tab title="Delivery Charge"  style="background-color: white; border-radius: 8px;">
                <list-delivery-charge/>
            </b-tab>
            <b-tab title="Delivery Exception"  style="background-color: white; border-radius: 8px;">
                <list-delivery-exception/>
            </b-tab>
            <b-tab title="Terms And Condition"  style="background-color: white; border-radius: 8px;">
                <TermsAndCondition/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import ListDeliveryCharge from './DeliveryCharge/ListDeliveryCharge';
import ListDeliveryException from './DeliveryException/ListDeliveryException';
import TermsAndCondition from './terms-and-condition/TermsAndCondition';

export default {
    name: 'ViewPage',
    components: { ListDeliveryException, ListDeliveryCharge, TermsAndCondition }
};
</script>

<style scoped>

</style>
