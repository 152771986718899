<template>
    <div>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                <div class="alert alert-info" v-if="infoMessage" v-html="infoMessage"></div>
                <b-row>
                    <b-col>
                        <label class="form-control-label demos">State</label>
                        <validated-input  name="State"
                                         :rules="{required: true}" v-model="model.state"/>
                    </b-col>
                    <b-col>
                        <label class="form-control-label demos">Delivery Charge</label>
                        <validated-input  name="Delivery Charge"
                                         :rules="{required: true}" v-model="model.delivery_charge"/>
                    </b-col>
                    <b-col>
                        <label class="form-control-label demos">Inactive</label>
                        <validated-checkbox class="form-control" name="Disable"
                                            :rules="{required: true}" v-model="model.disable"/>
                    </b-col>
                </b-row>
                <br>
                <loading-button loading-text="Saving..." text="Update" :saving="loading"></loading-button>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name: 'EditDeliveryCharge',
    props: { model: { type: Object } },
    data () {
        return {
            loading: false,
            infoMessage: '',
            errorMessage: ''
        };
    },
    methods: {
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            this.loading = true;
            const component = this;
            axios.form(urls.admin.masterData.deliveryCharge.addEdit, component.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Successfully Updated Delivery Charge..!', 'success',
                        {
                            type: 'success',
                            duration: 3000,
                            permanent: false
                        });
                    component.$emit('success');
                } else {
                    // console.log(json);
                    formValidator.setErrors(json.errors);
                    component.errorMessage = json.errors.all || '';
                    component.$notify(json.errors, 'Error',
                        {
                            type: 'warning',
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
                component.loading = false;
            }).catch(function () {
                component.loading = false;
                component.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        }
    }
};
</script>

<style scoped>

</style>
