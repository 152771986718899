<template>
    <div>
        <div class="float-right mt-3">
            <b-btn variant="success" size="md" @click="addItem"> +
            </b-btn>
        </div>
        <div class="p-4">
            <b-modal id="addDeliveryChargeModal" title="Add Delivery Charge"
                     :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                     hide-footer>
                <add-delivery-charge @success="ItemAdded"></add-delivery-charge>
            </b-modal>
            <vue-table class="mt-5" :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <b-btn variant="info" size="sm" @click="editItem(props.rowData)">
                            <b-icon-pencil-square></b-icon-pencil-square>
                            Edit
                        </b-btn>
                        <b-btn variant="danger" size="sm" @click="deleteItem(props.rowData.id)">
                            <b-icon-trash-fill></b-icon-trash-fill>
                            Delete
                        </b-btn>
                    </div>
                </template>
            </vue-table>
            <b-modal id="editDeliveryChargeModal" :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                     hide-footer
                     title="Edit Delivery Charge">
                <edit-delivery-charge :model="EditingModel" @success="itemEdited"></edit-delivery-charge>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import AddDeliveryCharge from './AddDeliveryCharge';
import EditDeliveryCharge from './EditDeliveryCharge';

export default {
    name: 'ListDeliveryCharge',
    components: { EditDeliveryCharge, AddDeliveryCharge },
    data () {
        return {
            apiBase: urls.admin.masterData.deliveryCharge.list,
            EditingModel: {
                id: '',
                state: '',
                delivery_charge: '',
                disable: ''
            },
            EditingCharge: null,
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'state',
                    sortField: 'state',
                    title: 'State'
                },
                {
                    name: 'delivery_charge',
                    sortField: 'delivery_charge',
                    title: 'Delivery Charge'
                },
                {
                    name: 'disable',
                    sortField: 'disable',
                    title: 'Active / Inactive'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        addItem () {
            this.$bvModal.show('addDeliveryChargeModal');
        },
        ItemAdded () {
            this.$bvModal.hide('addDeliveryChargeModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.EditingCharge = item;
            this.EditingModel.id = item.id;
            this.EditingModel.state = item.state;
            this.EditingModel.delivery_charge = item.delivery_charge;
            this.EditingModel.disable = item.disable;
            this.$bvModal.show('editDeliveryChargeModal');
        },
        itemEdited () {
            this.$bvModal.hide('editDeliveryChargeModal');
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const conform = confirm('Are you sure?');
            if (!conform) {
                return;
            }
            const that = this;
            axios.form(urls.admin.masterData.deliveryCharge.delete, { id: item }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.deleteSuccess();
                } else {
                    that.deleteFailure(json);
                }
            }).catch(function () {
                that.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        },
        deleteSuccess () {
            this.$notify('Successfully deleted Delivery Charge..!', 'success',
                {
                    type: 'error',
                    duration: 3000,
                    permanent: false
                });
            this.$refs.table.refreshTable();
        },
        deleteFailure (json) {
            // console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        }
    }
};
</script>

<style scoped>

</style>
